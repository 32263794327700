
import { computed, defineComponent, ref } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import GenericError from '@/components/GenericError.vue'
import NewsCard from '@/components/NewsCard.vue'
import { getNews } from '@/kentico-api/news.api'
import useSwrv from 'swrv/dist/use-swrv'
import { getPage, Page } from '@/kentico-api/submenus.api'
import { AxiosResponse } from 'axios'
import { useHead } from '@vueuse/head'
import imageUrlBuilder from '@/kentico-api/imageUrlBuilder'

export default defineComponent({
  components: { PageHeader, NewsCard, GenericError },
  name: 'ServiceNews',
  setup () {
    const { data: newsResponse, error } = useSwrv(
      '/news',
      getNews,
      {
        revalidateOnFocus: false,
        refreshInterval: 0
      }
    )

    const news = computed(() => {
      if (!newsResponse.value?.data) return []
      return Object.values(newsResponse.value.data)
        .filter(newsItem => newsItem.path)
    })

    const newsPage = ref()
    getPage('service-news')
      .then(({ data }: AxiosResponse<Page>) => {
        newsPage.value = data
      })
    useHead({
      title: computed(() => newsPage.value?.metadataPageTitle || ''),
      meta: [
        {
          name: 'keywords',
          content: computed(() => newsPage.value?.metadataMetaKeywords || ''),
          key: 'keywords'
        },
        {
          name: 'description',
          content: computed(() => newsPage.value?.metadataMetaDescription || ''),
          key: 'description'
        },
        {
          name: 'og:description',
          content: computed(() => newsPage.value?.openGraphOgDescription || ''),
          key: 'ogDescription'
        },
        {
          name: 'og:title',
          content: computed(() => newsPage.value?.openGraphOgTitle || ''),
          key: 'ogTitle'
        },
        {
          name: 'og:url',
          content: `${process.env.VUE_APP_SITE_URL}service-news`,
          key: 'ogUrl'
        },
        {
          name: 'og:image',
          itemprop: 'image',
          content: computed(() => newsPage.value && newsPage.value.openGraphOgImage?.length > 0
            ? imageUrlBuilder(newsPage.value.openGraphOgImage[0].url,
              { w: 800, auto: 'format' })
            : `${process.env.VUE_APP_SITE_URL}images/expressway-image.jpeg`),
          key: 'ogImage'
        },
        {
          name: 'og:locale',
          content: computed(() => newsPage.value?.openGraphOgLocale || ''),
          key: 'ogLocale'
        }
      ]
    })
    return {
      news,
      error,
      newsPage
    }
  }
})
